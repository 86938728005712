import { Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LogService } from '@services/log.service';
import { TenantService } from '@services/tenant.service';

import config from '@config';
import { ProgramStateService } from '@services/program-state.service';
import { IProduct, Product } from '@shared/models/product.model';
import { Program } from '@shared/models/program.model';
import Utils from '@shared/providers/utils';
import { orderBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private tenantService: TenantService,
    private logService: LogService,
    private programStateService: ProgramStateService,
  ) {}

  getProducts(): Observable<any[]> {
    return of(this.allUniqueProductsForTenant);
  }

  private get allUniqueProductsForTenant(): string[] {
    const allProducts: string[] = [];
    this.currentTenantPrograms.forEach(program => {
      if (program.products) {
        allProducts.push(...program.products.map(product => product.name));
      }
    });

    return allProducts.filter(Utils.uniqueFilter);
  }

  private get currentTenantPrograms(): Program[] {
    return this.programStateService.allProgramsForTenant.getValue();
  }

  getTenantProducts(): Observable<IProduct[]> {
    return merge(of(true), this.tenantService.activeTenantChanged).pipe(map(() => this.getCurrentTenantProducts()));
  }

  getProductsForTenant(tenantId: string): IProduct[] {
    const products: Record<string, any> = config.products;

    if (!products[tenantId]) {
      throw new Error(`Missing products list for tenant: ${tenantId}`);
    }

    return orderBy(products[tenantId], 'name');
  }

  getProductsForAllTenants(): Record<string, IProduct[]> {
    const products: Record<string, any> = config.products;

    if (!products) {
      throw new Error(`Missing products list`);
    }

    return products;
  }

  getCurrentTenantProducts(): IProduct[] {
    return this.getProductsForTenant(this.tenantService.getCurrentTenantId());
  }

  getProduct(ndc: string): Product | undefined {
    const productFields = this.currentTenantPrograms
      .filter(program => program.products)
      .map(program => program.products)
      .reduce((flattenedProducts, products) => flattenedProducts.concat(products), [])
      .find(tenantProduct => tenantProduct.ndc === ndc);
    if (!productFields) {
      return undefined;
    }
    return new Product(productFields);
  }

  getAllProductNdc(): string[] {
    const products = this.currentTenantPrograms
      .filter(program => program.products)
      .map(program => program.products)
      .reduce((flattenedProducts, products) => flattenedProducts.concat(products), [])
      .filter(product => !!product.ndc);
    return products.map(product => product.ndc);
  }

  getAllProductNdcFromName(productName: string): string[] {
    const productsWithSpecifiedName = this.currentTenantPrograms
      .filter(program => program.products)
      .map(program => program.products)
      .reduce((flattenedProducts, products) => flattenedProducts.concat(products), [])
      .filter(tenantProduct => {
        return tenantProduct && tenantProduct.name && tenantProduct.name.toLowerCase().startsWith(productName.trim().toLowerCase());
      });
    return productsWithSpecifiedName.map(product => product.ndc);
  }

  getAllProductNdcFromHowSupplied(howSupplied: string): string[] {
    const productsWithSpecifiedName = this.currentTenantPrograms
      .filter(program => program.products)
      .map(program => program.products)
      .reduce((flattenedProducts, products) => flattenedProducts.concat(products), [])
      .filter(tenantProduct => {
        return (
          tenantProduct && tenantProduct.howSupplied && tenantProduct.howSupplied.toLowerCase().includes(howSupplied.trim().toLowerCase())
        );
      });
    return productsWithSpecifiedName.map(product => product.ndc);
  }
}
