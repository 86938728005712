import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { ClaimFlagSettingsSchema } from '@libs/shared/domain/claim/flag/claim-flag-settings.schema';
import { ClaimFlagsService } from '@services/claim-flags.service';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';
import { FlagsMap } from '@shared/components/claim-flags/claim-flags-types';

@Component({
  selector: 'app-shared-claim-flags-detail',
  templateUrl: './claim-flags-detail.component.html',
  styleUrl: './claim-flags-detail.component.scss',
})
export class ClaimFlagsDetailComponent {
  private readonly claimFlagsService = inject(ClaimFlagsService);
  readonly flagCategoryCountType!: { value: number; label: string; class: string };
  readonly flagCategoryDetailsType!: { flags: ClaimFlagSettingsSchema[]; label: string; class: string };

  claim = input.required<PatientAssistanceRequestViewModel>();
  flagsMap = signal<FlagsMap>(this.claimFlagsService.getFlagsMap());
  scanId = signal('');
  flagsScanned = signal(0);
  flagsCount = computed(() => this.claim().flagsList?.length ?? 0);
  flagsIdentity = computed(() => this.claimFlagsService.getFlagsIdenity(this.flagsMap()));

  constructor() {
    effect(() => this.onClaimChange(this.claim()), { allowSignalWrites: true });
  }

  private onClaimChange(claim: PatientAssistanceRequestViewModel) {
    this.scanId.set(new Date(claim.created).getTime().toString().slice(3, 10));
    this.claimFlagsService.getFlagsSettings(claim.tenantId).subscribe(flags => {
      this.flagsMap.set(this.claimFlagsService.getFlagsMap(claim, flags));
      this.flagsScanned.set(flags.length);
    });
  }
}
