import { makeUniqueId } from "./makeUniqueId.js";
export function stringifyForDisplay(value, space) {
    if (space === void 0) { space = 0; }
    var undefId = makeUniqueId("stringifyForDisplay");
    return JSON.stringify(value, function (key, value) {
        return value === void 0 ? undefId : value;
    }, space)
        .split(JSON.stringify(undefId))
        .join("<undefined>");
}
