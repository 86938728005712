import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AUTH_REQUIRED } from '@app/tokens';
import config from '@config';
import {
  ROLE_ADMINISTRATOR,
  ROLE_FIELD_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_PHARMACY,
  ROLE_PHARMACY_OWNER,
  ROLE_PROGRAM_ADMIN,
  ROLE_PROGRAM_ADMIN_READ,
  ROLE_RETAIL_PHARMACY,
} from '@libs/shared/domain/master-data/user/shared-master-data-user-role.type';
import { AuthService } from '@services/auth.service';
import { BaseService } from '@services/base.service';
import { PharmacyViewModel } from '@services/models/pharmacy-view.model';
import { TokenClaimResponse } from '@services/models/token-claim-response';
import { PharmaciesSearchService } from '@services/pharmacies.search.service';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { AgreementType } from '@shared/models/agreement-type.enum';
import { IPermission } from '@shared/models/permission.model';
import { IRole } from '@shared/models/role.model';
import { SelectListItem } from '@shared/models/select-list-item';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { Tenant } from '@shared/models/tenant.model';
import { UserActionType } from '@shared/models/user-action.type';
import { UserRole } from '@shared/models/user-role.model';
import { UserStatusType } from '@shared/models/user-status.type';
import { IUser, REQUIRED_USER_FIELDS } from '@shared/models/user.model';
import {
  OpenSearchPage,
  OpenSearchQueryContainer,
  OpenSearchQuerySimpleResult,
  OpenSearchSimpleSort,
} from '@shared/providers/opensearch-api';
import Utils from '@shared/providers/utils';
import { ListIterator, isObject } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

interface StatusActionTextColor {
  action: UserActionType;
  text: string;
  color: 'danger' | 'success';
}

type StatusActionMappingType = {
  [index in UserStatusType]: StatusActionTextColor;
};

export const ROLES_WITH_ALL_TENANT_ACCESS = [ROLE_ADMINISTRATOR, ROLE_FINANCE_ADMIN, ROLE_PHARMACY_OWNER];
export const ROLES_WITH_ALL_PHARMACIES_ACCESS = [ROLE_ADMINISTRATOR, ROLE_FINANCE_ADMIN, ROLE_PROGRAM_ADMIN];

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  readonly userChanged = new Subject<void>();
  selectedUser: IUser;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private tenantService: TenantService,
    private urlService: UrlService,
    private pharmaciesSearchService: PharmaciesSearchService,
  ) {
    super();
  }

  isUserAuthenticated() {
    return this.authService.isUserAuthenticated();
  }

  hasAllTenantsAccess(): boolean {
    return ROLES_WITH_ALL_TENANT_ACCESS.includes(this.getCurrentUser().role);
  }

  hasAllPharmaciesAccess(): boolean {
    return ROLES_WITH_ALL_PHARMACIES_ACCESS.includes(this.getCurrentUser().role);
  }

  isSuperAdmin(): boolean {
    const roles = [ROLE_ADMINISTRATOR, ROLE_FINANCE_ADMIN];
    return roles.includes(this.getCurrentUser().role);
  }

  getCurrentUser(): IUser {
    return this.authService.getCurrentUser();
  }

  getUserList() {
    this.isLoading.next(true);
    return this.httpClient.post<IUser[]>(this.urlService.USER_URL, '{}');
  }

  validateToken(token: string): Observable<TokenClaimResponse> {
    return this.httpClient.get<TokenClaimResponse>(this.urlService.TOKENS_URL + '/' + token, {
      context: new HttpContext().set(AUTH_REQUIRED, false),
    });
  }

  getUserAccessibleTenants(): Tenant[] {
    return this.tenantService
      .getAllTenants()
      .filter(tenant => (this.hasAllTenantsAccess() ? true : this.getCurrentUser().tenants?.includes(tenant.id as TenantIdType)));
  }

  retrieveLatestVersionOfUser(): void {
    const user = this.authService.getCurrentUser();

    if (user && user.username) {
      this.getUser(user.username).subscribe(retrievedUser => {
        // HIGH RISK - Be very careful here to only save the user if
        // it's the same username as the currently logged in user.
        if (retrievedUser.username === user.username) {
          this.authService.saveUser(retrievedUser);
        }
      });
    }
  }

  update(user: IUser) {
    this.isLoading.next(true);
    return this.httpClient
      .patch(this.urlService.USER_URL + '/' + user.username, JSON.stringify(user))
      .pipe(tap(() => this.isLoading.next(false)));
  }

  updateCurrentUser(userData: Partial<IUser>) {
    const user = Object.assign(this.getCurrentUser(), userData);
    return this.update(user).pipe(
      tap(() => {
        this.authService.saveUser(user);
        this.userChanged.next();
      }),
    );
  }

  getPharmacies(): Observable<PharmacyViewModel[]> {
    const filter: OpenSearchQueryContainer[] = [{ terms: { ncpdp: this.getCurrentUser().ncpdps ?? [] } }];
    const page: OpenSearchPage = { from: 0, size: 100 };
    const sort: OpenSearchSimpleSort = { name: 'asc' };

    return this.pharmaciesSearchService.searchData(filter, page, sort).pipe(
      map((res: OpenSearchQuerySimpleResult<PharmacyViewModel>) => {
        return res.items;
      }),
    );
  }

  setSelectedUser(user: IUser) {
    this.selectedUser = user;
  }

  reset(): void {}

  searchUserByRole(role: string): Observable<IUser[]> {
    const currentTenant = this.tenantService.getCurrentTenantData();
    const body = {
      role: role,
      tenant: currentTenant?.id,
    };

    return this.httpClient.post<IUser[]>(this.urlService.USER_URL, body);
  }

  searchUserByNcpdp(ncpdp: string): Observable<IUser[]> {
    const body = {
      ncpdp: ncpdp,
    };

    return this.httpClient.post<IUser[]>(this.urlService.USER_URL, body);
  }

  searchUserByUsername(username: string): Observable<IUser[]> {
    const body = {
      username: username,
    };

    return this.httpClient.post<IUser[]>(this.urlService.USER_URL, body);
  }

  searchUserByEmail(email: string): Observable<IUser[]> {
    const body = {
      email: email,
    };

    return this.httpClient.post<IUser[]>(this.urlService.USER_URL, body);
  }

  userAction(action: UserActionType, username: string): Observable<IUser> {
    const body = {
      username: username,
    };

    return this.httpClient.post<IUser>(this.urlService.USER_URL + '/' + action, body);
  }

  getUserWithTrimmedValues(user: IUser): IUser {
    const _user = Utils.copyObject<Record<string, any>>(user);
    Object.keys(_user).forEach(field => {
      if (typeof _user[field] === 'string') {
        _user[field] = _user[field].trim();
      }
    });
    return _user as IUser;
  }

  doRequiredFieldsExist(user: IUser): Map<string, boolean> {
    return Utils.doFieldsExist(user, REQUIRED_USER_FIELDS);
  }

  getRoles(): Observable<IRole[]> {
    return this.httpClient.get<IRole[]>(this.urlService.ROLES_URL);
  }

  getUserRoles(): Observable<string[]> {
    this.isLoading.next(true);
    return this.httpClient.get<string[]>(this.urlService.USER_URL + '/roles');
  }

  isInRole(role: string) {
    const user = this.authService.getCurrentUser();
    if (user && user.role) {
      return user.role === role;
    }
    return false;
  }

  isAdministrator() {
    return this.isInRole(ROLE_ADMINISTRATOR);
  }

  isFinanceAdmin() {
    return this.isInRole(ROLE_FINANCE_ADMIN);
  }

  isFieldAdmin() {
    return this.isInRole(ROLE_FIELD_ADMIN);
  }

  isProgramAdmin() {
    return this.isInRole(ROLE_PROGRAM_ADMIN);
  }

  isPharmacy() {
    return this.isInRole(ROLE_PHARMACY);
  }

  isApiPharmacy() {
    return this.isInRole('ApiPharmacy');
  }

  isRetailPharmacy(): boolean {
    return this.isInRole(ROLE_RETAIL_PHARMACY);
  }

  isProgramAdminRead(): boolean {
    return this.isInRole(ROLE_PROGRAM_ADMIN_READ);
  }

  isComponentVisible(componentReference: string) {
    const currentRole = this.authService.getCurrentUserRole();
    return currentRole ? this.isComponentAccessGranted(componentReference, currentRole.permissions, true) : false;
  }

  isComponentVisibleForTenant(componentReference: string) {
    const currentTenantId = this.tenantService.currentTenant?.id ?? '';
    const componentsVisible: Record<string, string[]> = config.componentsVisible;
    const componentSettings = componentsVisible[componentReference];
    return componentSettings ? componentSettings.includes(currentTenantId) : true;
  }

  isComponentReadOnly(componentReference: string) {
    let isReadOnly = false;
    const currentRole = this.authService.getCurrentUserRole();
    currentRole?.permissions.forEach(permission => {
      if (!isReadOnly) {
        isReadOnly = this.isReadOnly(permission, componentReference);
      }
    });
    return isReadOnly;
  }

  isComponentAccessGranted(componentReference: string, permissions: IPermission[] = [], checkVisibility = false) {
    if (permissions.length === 0) {
      permissions = this.authService.getCurrentUserRole().permissions ?? permissions;
    }
    let hasRestriction = false;
    let hasAccess = false;
    const hasAdminFullAccess = this.hasFullAccessPermission(permissions);
    permissions.forEach(permission => {
      if (this.isAccessGranted(permission, this.getComponentName(componentReference))) {
        if (checkVisibility) {
          if (this.isVisible(permission, this.getComponentName(componentReference))) {
            hasAccess = true;
          } else {
            hasRestriction = true;
          }
        } else {
          hasAccess = true;
        }
      } else if (this.isAccessDenied(permission, this.getComponentName(componentReference))) {
        hasRestriction = true;
      }
    });
    return hasAccess || (hasAdminFullAccess && !hasRestriction);
  }

  acceptAgreement(user: IUser, agreement: AgreementType) {
    if (!this.hasAgreement(user, agreement)) {
      user.accepted_agreements = user.accepted_agreements ?? [];
      user.accepted_agreements?.push({
        id: agreement,
        dateModified: new Date().toISOString(),
      });
    }
    return user;
  }

  hasAgreement(user: IUser, agreement: AgreementType) {
    return !!(user.accepted_agreements ?? []).find(item => isObject(item) && item.id === agreement);
  }

  hasAcceptedAgreements(user: IUser): boolean {
    const roles: Record<string, AgreementType> = {
      [UserRole.PharmacyOwner]: AgreementType.USER_PHARMACY_OWNER,
    };
    const roleAgreement = roles[user.role];
    return roleAgreement ? this.hasAgreement(user, roles[user.role]) : true;
  }

  isComponentAllowedForRoute(route: ActivatedRoute): boolean | null {
    while (route.firstChild) {
      route = route.firstChild;
    }

    const componentName: string = route?.snapshot.data?.componentName;
    if (!componentName) {
      return null;
    }

    return this.isComponentVisible(componentName) && this.isComponentVisibleForTenant(componentName);
  }

  getUserListOptions(filter?: ListIterator<IUser, boolean>): Observable<SelectListItem[]> {
    return this.getUserList().pipe(
      shareReplay(1),
      map(users => (filter ? users.filter(filter) : users)),
      map(users => {
        return users
          .map(user => ({ name: `${user.last_name} ${user.first_name}`, value: user.username, group: user.role }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
    );
  }

  getUserRolesFilter(roles: string[]): ListIterator<IUser, boolean> {
    return user => roles.includes(user.role);
  }

  isUsernameUnique(username: string): Observable<boolean> {
    return this.getUserList().pipe(map(users => users.findIndex(user => user.username === username) === -1));
  }

  isEmailUnique(email: string): Observable<boolean> {
    return this.getUserList().pipe(map(users => users.findIndex(user => user.email === email) === -1));
  }

  static readonly STATUS_ACTION_MAPPINGS: StatusActionMappingType = {
    active: { action: 'disable', text: 'Disable User', color: 'danger' },
    disabled: { action: 'enable', text: 'Activate User', color: 'success' },
    expired: { action: 'reinvite', text: 'Resend Invite', color: 'success' },
    pending: { action: 'reinvite', text: 'Resend Invite', color: 'success' },
  };

  static readonly USER_STATUS_CLASS = {
    active: 'success',
    disabled: 'primary',
    expired: 'danger',
    pending: 'warning',
  };

  private getUser(username: string) {
    return this.httpClient.get<IUser>(`${this.urlService.USER_URL}/${username}`);
  }

  private getComponentName(componentReference: string): string {
    // let result = componentReference;
    // const tenantPages = ['SupportComponent', 'FaqComponent'];
    // const tenantRequired = tenantPages.includes(componentReference);
    // const currentTenant = this.tenantService.getCurrentTenantData();
    // if (tenantRequired && currentTenant) {
    //   result = `${currentTenant.name}${componentReference}`;
    // }
    // return result;
    return componentReference;
  }

  private hasFullAccessPermission(permissions: IPermission[]): boolean {
    let result = false;
    permissions.forEach(permission => {
      if (permission.component === '*' && permission.effect === 'Allow') {
        result = true;
      }
    });
    return result;
  }

  private isAccessGranted(permission: IPermission, componentName: string): boolean {
    return permission.component === componentName && permission.effect === 'Allow';
  }

  private isAccessDenied(permission: IPermission, componentName: string): boolean {
    return permission.component === componentName && permission.effect === 'Deny';
  }

  private isVisible(permission: IPermission, componentName: string): boolean {
    return permission.component === componentName && permission.visible;
  }

  private isReadOnly(permission: IPermission, componentName: string): boolean {
    return permission.component === componentName && permission.action === 'ReadOnly';
  }
}
